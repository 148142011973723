import Box from '@mui/material/Box';
import Image from 'next/image';
import { getCaseImageUrl } from 'utils/outerImages';

const BattleCaseImage = ({ item }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70px',
        height: '10px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          width: '100%',
          height: '75px',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            width: 1,
            height: 1,
            justifyContent: 'center'
          }}
        >
          {item?.caseImg && (
            <Image
              unoptimized
              loading='eager'
              src={getCaseImageUrl(item?.caseImg)}
              alt={'case-battles'}
              // layout='fill'
              // objectFit='contain'
              fill
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              // placeholder='blur'
              // blurDataURL={getCaseImageUrl(item?.caseImg)}
            />
          )}
          {item?.rifleImg && (
            <Box
              className={'case__rif'}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                marginTop: '0',
                transition: '.3s ease-in-out'
              }}
            >
              <Image
                unoptimized
                loading='eager'
                src={getCaseImageUrl(item?.rifleImg, true)}
                alt={'case-battles'}
                // layout='fill'
                // objectFit='contain'
                fill
                style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                // placeholder='blur'
                // blurDataURL={getCaseImageUrl(item?.rifleImg, true)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BattleCaseImage;
