import { Box, useTheme } from '@mui/material';
import { SmallTimeUnit } from '../TimeUnit';
import useCountdown from 'hooks/useCountdown';

const GiveawayCountdown = ({ createdAt, period }) => {
  const theme = useTheme();
  const durations = useCountdown({ createdAt, period });

  return (
    <Box
      display='flex'
      gap={0.5}
      minWidth={145}
      justifyContent='center'
      width='fit-content'
      p={theme.spacing(1, 1.5)}
      borderRadius={theme.spacing(1)}
      bgcolor={theme.palette.greyTransparency[600]}
    >
      <SmallTimeUnit name={'days'} value={durations.days} />
      <SmallTimeUnit name={'hours'} value={durations.hours} />
      <SmallTimeUnit name={'minutes'} value={durations.minutes} />
      <SmallTimeUnit name={'seconds'} value={durations.seconds} />
    </Box>
  );
};

export default GiveawayCountdown;
