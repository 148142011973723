import { Box, Typography } from '@mui/material';

const LARGE_TIME_UNIT = {
  days: 'Days',
  hours: 'Hours',
  minutes: 'Min',
  seconds: 'Sec'
};

export const LargeTimeUnit = ({ name, value }) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography
        variant='h5'
        fontSize={{
          phxs: '14px !important',
          phlg: '16px !important',
          tbxs: '28px !important'
        }}
      >
        {value}
      </Typography>
      <Typography
        variant='body2'
        fontSize={{
          phxs: '10px !important',
          phlg: '12px !important',
          tbxs: '14px !important'
        }}
      >
        {LARGE_TIME_UNIT[name]}
      </Typography>
    </Box>
  );
};

// ----------  -----------

export const LargeTimeUnitEvent = ({
  name,
  value = 0
}: {
  name: string;
  value: number | string;
}) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Typography
        variant='h6'
        fontSize={{
          phxs: '24px !important'
          // phlg: '16px !important',
          // tbxs: '28px !important'
        }}
      >
        {Number(value)}
      </Typography>
      <Typography
        variant='caption'
        fontSize={{
          phxs: '12px !important'
          // phlg: '12px !important',
          // tbxs: '14px !important'
        }}
      >
        {LARGE_TIME_UNIT[name]}
      </Typography>
    </Box>
  );
};

// ----------  -----------

const SMALL_TIME_UNIT = {
  days: 'd',
  hours: 'h',
  minutes: 'm',
  seconds: 's'
};
export const SmallTimeUnit = ({ name, value }) => {
  return (
    <Typography variant='body1'>
      {value}
      {SMALL_TIME_UNIT[name]}
    </Typography>
  );
};
