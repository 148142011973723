import { useCallback, useState } from 'react';
import { alpha, Box, useTheme } from '@mui/material';

import GiveawayDetails from './GiveawayDetails';
import WinItemImage from '../WinItemImage';
import GiveawayDialog from '../Dialog/GiveawayDialog';

import { GIVEAWAY_TEMPLATES } from 'constants/giveaway';

const GiveawayItem = ({ item }) => {
  const theme = useTheme();
  const [modalState, setModalState] = useState(false);

  const giveawayTemplate = GIVEAWAY_TEMPLATES[item?.period];

  const openModal = useCallback(() => {
    setModalState(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalState(false);
  }, []);

  return (
    <Box
      width={1}
      display='flex'
      borderRadius={4}
      minHeight={{
        phxs: 200,
        tb: 205
      }}
      // height={{
      //   tbxs: 100,
      //   phlg: 210
      // }}
      overflow='auto'
      border={`1px solid ${theme.palette.giveaway.border[item?.period]}`}
      sx={{
        cursor: 'pointer',
        background: theme.palette.giveaway.card[item?.period]
      }}
    >
      <Box
        width={1}
        display='flex'
        justifyContent='space-between'
        position='relative'
        onClick={openModal}
        sx={{
          backgroundImage: `url(${giveawayTemplate?.image?.src})`,
          backgroundPosition: {
            phxs: '150% top',
            phlg: '120% top',
            tbxs: '110% top',
            tb: '100% top',
            tblg: '120% top',
            lp: '110% top',
            dtxs: '150% top'
          },
          backgroundRepeat: 'no-repeat',
          backgroundSize: {
            phxs: '75%',
            phlg: '65%',
            tbxs: '50%',
            tb: '60%',
            tblg: '65%',
            lp: '55%',
            lplg: '50%',
            dtxs: '75%'
          }
        }}
      >
        {/* --------------- 50/50 ---------------- */}
        <GiveawayDetails
          period={item?.period}
          createdAt={item?.createdAt}
          text={giveawayTemplate.text}
          winItem={item?.winItem}
          participantsCount={item?.participantsCount}
        />

        {/* ITEM IMAGE */}
        <Box
          height={1}
          width={'45%'}
          position='absolute'
          right={{
            phxs: 0,
            phlg: 8
          }}
        >
          <WinItemImage itemName={item?.winItem?.itemName} itemIcon={item?.winItem?.icon} />
        </Box>
      </Box>

      {/* DIALOG */}
      {modalState && (
        <GiveawayDialog
          giveawayId={item?.id}
          modalState={modalState}
          handleCloseModal={closeModal}
          period={item?.period}
          winItem={item?.winItem}
          createdAt={item?.createdAt}
        />
      )}
    </Box>
  );
};

export default GiveawayItem;
