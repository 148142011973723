import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const BattleSkileton = ({ numberOfSkeleton, maxNum }) => {
  const theme = useTheme();
  const sk = [];

  for (let i = 0; i < numberOfSkeleton; i++) {
    sk.push(
      <Box
        key={`battle-skeleton-${i}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
          width: '100%',
          minWidth: 300,
          // width: `calc((100% - (${maxNum - 1} * 10px)) / ${maxNum})`,
          height: 228,
          background: theme.palette.grey[800],
          borderRadius: '10px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            align: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '15px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '10px'
            }}
          >
            <Skeleton variant='circular' animation='wave' width={'32px'} height={'32px'} />
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={55}
              height={9}
              sx={{ borderRadius: '5px' }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '4px'
            }}
          >
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={'32px'}
              height={'32px'}
              sx={{ borderRadius: '5px' }}
            />
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={'32px'}
              height={'32px'}
              sx={{ borderRadius: '5px' }}
            />
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={'32px'}
              height={'32px'}
              sx={{ borderRadius: '5px' }}
            />
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={'32px'}
              height={'32px'}
              sx={{ borderRadius: '5px' }}
            />
          </Box>
        </Box>

        <Skeleton
          variant='rectangular'
          animation='wave'
          width={'100%'}
          height={84}
          sx={{ background: theme.palette.grey[900] }}
        />

        <Box
          sx={{
            display: 'flex',
            align: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '15px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: '7px'
            }}
          >
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={80}
              height={24}
              sx={{ borderRadius: '5px' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '10px'
            }}
          >
            <Skeleton
              variant='rectangular'
              animation='wave'
              width={150}
              height={48}
              sx={{ borderRadius: '8px' }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return <>{sk?.map((item) => item)}</>;
};

export default BattleSkileton;
