import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BattlesList from './BattlesList';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from 'store';
import { useLoginModalContext } from 'context/modals/LoginModalProvider/LoginModalProvider';

const BattlesContainer = () => {
  const router = useRouter();
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('tbxs'));

  const isAuthenticated = useAppSelector((s) => s.auth.isAuthenticated);
  const { showLoginModal } = useLoginModalContext();

  const handleClickCreateBattle = () => {
    if (isAuthenticated) {
      router.push('/battle/create');
    } else {
      showLoginModal();
    }
  };

  return (
    <Box display='flex' flexDirection='column' gap={3} width={1}>
      <Box
        display={'flex'}
        width={1}
        justifyContent={{ phxs: 'center', tb: 'flex-end' }}
        position={'relative'}
        alignItems={'center'}
        height={40}
      >
        <Typography
          variant='h5'
          textTransform='uppercase'
          whiteSpace={'nowrap'}
          textAlign={'center'}
          position={{ phxs: 'relative', tb: 'absolute' }}
          sx={{ left: 0, right: 0 }}
        >
          Case Battle
        </Typography>

        <Box display={{ phxs: 'none', tb: 'flex' }} gap={1} whiteSpace={'nowrap'}>
          <Button
            // fullWidth
            variant='outlined'
            color='secondary'
            size='small'
            onClick={() => router.push('/battle/list')}
          >
            View All
          </Button>

          <Button
            // fullWidth
            variant='contained'
            color='secondary'
            size='small'
            onClick={handleClickCreateBattle}
          >
            Create battle
          </Button>
        </Box>
      </Box>

      <BattlesListContainer />

      <Box
        display={{ phxs: 'flex', tb: 'none' }}
        gap={1}
        width={1}
        sx={{
          width: 1
        }}
      >
        <Button
          fullWidth
          variant='outlined'
          color='secondary'
          size='small'
          onClick={() => router.push('/battle/list')}
        >
          View All
        </Button>

        <Button
          fullWidth
          variant='contained'
          color='secondary'
          size='small'
          onClick={handleClickCreateBattle}
        >
          Create battle
        </Button>
      </Box>
    </Box>
  );
};

export default BattlesContainer;

const BattlesListContainer = () => {
  // const theme = useTheme();
  const itemsCount = 5;

  // if (useMediaQuery(theme?.breakpoints?.down('dt'))) {
  //   itemsCount = 4;
  // }
  // if (useMediaQuery(theme?.breakpoints?.down('dtxs'))) {
  //   itemsCount = 3;
  // }
  // if (useMediaQuery(theme?.breakpoints?.down('lpxs'))) {
  //   itemsCount = 2;
  // }
  // if (useMediaQuery(theme?.breakpoints?.down('tbxs'))) {
  //   itemsCount = 1;
  // }

  return (
    <Box width={1}>
      <BattlesList maxNum={itemsCount} />
    </Box>
  );
};
