import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Image from 'next/image';
import { Case } from 'models/types/cases';
import { getCaseImageUrl } from 'utils/outerImages';
import Link from 'next/link';

import { formatCaseName, lowerCaseName } from 'utils/string';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material';
import { EventLogo } from 'components/User/EventTokens';
import { useGetEventTokensActiveQuery } from 'store/rtk/events/service';

type CaseProps = {
  item: Case;
  tokensWinRate?: number;
};

const CaseItem = ({ item, tokensWinRate }: CaseProps) => {
  const theme = useTheme();
  const { data: activeToken } = useGetEventTokensActiveQuery(undefined, { skip: !tokensWinRate });

  const caseImage = item?.caseImg ? getCaseImageUrl(item?.caseImg) : '';
  const rifleImage = item?.rifleImg ? getCaseImageUrl(item?.rifleImg, true) : '';

  return (
    <Link
      passHref
      href={tokensWinRate ? '/event/case/[name]' : '/case/[name]'}
      as={
        tokensWinRate
          ? `/event/case/${lowerCaseName(item?.name)}`
          : `/case/${lowerCaseName(item?.name)}`
      }
    >
      <Box
        display='flex'
        flexDirection='column'
        position='relative'
        width={1}
        height={'100%'}
        minHeight={{
          phxs: 240,
          tbxs: 300,
          tb: 360,
          tblg: 300,
          lpxs: 330,
          lp: 290,
          lplg: 330,
          dtxs: 240,
          dt: 290
          // dtxs: 330, // 240
          // dt: 340 // 290
        }}
        component={motion.div}
        initial='init'
        whileHover='hover'
        whileTap='click'
        sx={{
          cursor: 'pointer',
          ':hover': {
            '& .MuiButton-root': {
              backgroundColor: theme.palette.primaries[400],
              color: theme.palette.primaries[900],
              path: {
                fill: theme.palette.primaries[900]
              }
            }
          }
        }}
      >
        <Box
          display='flex'
          position='relative'
          width='auto'
          height='100%'
          justifyContent='center'
          component={motion.div}
          variants={{
            init: { scale: 1 },
            hover: { scale: 1.1 },
            click: { scale: 0.9 }
          }}
          className='case-item-image-caseImg'
        >
          {item?.caseImg && (
            <Image
              unoptimized
              loading='eager'
              src={caseImage}
              alt={item?.name}
              // layout='fill'
              // objectFit='contain'
              fill
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              quality={50}
              // placeholder='blur'
              // blurDataURL={caseImage}
            />
          )}
          {item?.rifleImg && (
            <Box
              position='relative'
              width={1}
              height={1}
              component={motion.div}
              variants={{
                init: { y: 0, scale: 1 },
                hover: { y: -10, scale: 1.1 }
              }}
            >
              <Image
                unoptimized
                loading='eager'
                src={rifleImage}
                alt={item?.name}
                fill
                style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                quality={50}
              />
            </Box>
          )}
        </Box>

        <Box display='flex' gap={1.5} flexDirection='column' alignItems='center'>
          <Typography variant='body1' fontWeight={500}>
            {formatCaseName(item?.name)}
          </Typography>
          <Button
            variant='tertiary'
            size='small'
            sx={{
              minWidth: theme.spacing(17)
            }}
          >
            ${item?.price.toFixed(2)}
          </Button>
        </Box>

        {tokensWinRate && activeToken ? (
          <Box position='absolute' top={16} right={16} display='flex' gap={0.5}>
            <EventLogo width={24} height={24} icon={activeToken?.icon} title={activeToken?.title} />
            <Typography variant='body1'>{tokensWinRate}%</Typography>
          </Box>
        ) : null}
      </Box>
    </Link>
  );
};

export default React.memo(CaseItem);
