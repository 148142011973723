import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LargeTimeUnit } from '../TimeUnit';
import useCountdown from 'hooks/useCountdown';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.greyTransparency[700]
}));

export const COUNTDOWN_WIDTH = {
  phxs: 35,
  phlg: 40,
  tbxs: 50
};

export const COUNTDOWN_HEIGHT = {
  phxs: 34,
  phlg: 40,
  tbxs: 66
};

const DialogCountdown = ({ createdAt, period }) => {
  const durations = useCountdown({ createdAt, period });

  return (
    <Box
      display='flex'
      gap={0.5}
      justifyContent='center'
      width='fit-content'
      height={COUNTDOWN_HEIGHT}
    >
      <StyledBox p={1} width={COUNTDOWN_WIDTH} borderRadius={2}>
        <LargeTimeUnit name={'days'} value={durations.days} />
      </StyledBox>

      <StyledBox p={1} width={COUNTDOWN_WIDTH} borderRadius={2}>
        <LargeTimeUnit name={'hours'} value={durations.hours} />
      </StyledBox>

      <StyledBox p={1} width={COUNTDOWN_WIDTH} borderRadius={2}>
        <LargeTimeUnit name={'minutes'} value={durations.minutes} />
      </StyledBox>

      <StyledBox p={1} width={COUNTDOWN_WIDTH} borderRadius={2}>
        <LargeTimeUnit name={'seconds'} value={durations.seconds} />
      </StyledBox>
    </Box>
  );
};

export default DialogCountdown;
