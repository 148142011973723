import type { RarityColorKeys } from 'models/types/rarity';

export const formatRarity = (rarity = ''): RarityColorKeys => {
  const formatedKey: RarityColorKeys = rarity?.toLowerCase().replace(/\s/g, '_');
  return formatedKey;
}

export const shortifyExterior = (exterior: string) => {
  if (!exterior) return ''

  const [a, b] = exterior.split('-');
  const [c, d] = exterior.split(' ');

  if (c && d) {
    return `${c[0]}${d[0]}`.toUpperCase()
  }

  return `${a[0]}${b[0]}`.toUpperCase()
}