import Typography from '@mui/material/Typography';
import { Box, DialogTitle, IconButton, useTheme } from '@mui/material';

import CloseIcon from 'oldAssets/new/MarketClose.svg';
import WinItemImage from '../WinItemImage';
import GiveawayItemPrice from '../GiveawayItemPrice';
import DialogCountdown from '../Dialog/DialogCountdown';

import { GIVEAWAY_TEMPLATES } from 'constants/giveaway';
import { shortifyExterior } from 'utils/rarity';

const DialogHeader = ({ period, winItem, createdAt, handleCloseModal }) => {
  const theme = useTheme();
  const giveawayTemplate = GIVEAWAY_TEMPLATES[period];

  return (
    <DialogTitle id='scroll-dialog-title' sx={{ p: 0 }}>
      <Box
        display='flex'
        height={{ phxs: 160, phlg: 175, tbxs: 142 }}
        overflow='auto'
        borderRadius={theme.spacing(2, 2, 0, 0)}
        border={`2px solid ${theme.palette.giveaway.border[period]}`}
        sx={{
          background: theme.palette.giveaway.modal[period]
        }}
      >
        <Box
          width={1}
          display='flex'
          justifyContent='space-between'
          position='relative'
          sx={{
            backgroundImage: `url(${giveawayTemplate?.image?.src})`,
            backgroundPosition: {
              phxs: '10% top',
              tbxs: '5% top'
            },
            backgroundRepeat: 'no-repeat',
            backgroundSize: {
              phxs: '60%',
              tbxs: '30%'
            }
          }}
        >
          <Box display='flex' gap={2} width={1} height={1}>
            <Box width={{ phxs: 2 / 5, tbxs: 3 / 12, tb: 1 / 3 }}>
              <WinItemImage itemName={winItem?.itemName} itemIcon={winItem?.icon} />
            </Box>

            <Box
              display='flex'
              flexDirection={{
                phxs: 'column',
                tbxs: 'row'
              }}
              width={{ phxs: 3 / 5, tbxs: 7 / 12, tb: 2 / 3 }}
              alignItems='center'
              justifyContent='flex-start'
            >
              {/* DETAILS  */}
              <Box
                gap={{ phxs: 0, tbxs: 1 }}
                pt={1}
                pb={{ phxs: 0, tbxs: 1 }}
                width={{ phxs: 1, tbxs: 1 / 2 }}
                display='flex'
                flexDirection='column'
              >
                <Typography variant='subtitle1'>{giveawayTemplate.text}</Typography>

                <Box display='flex' justifyContent='center' flexDirection='column'>
                  {winItem?.weaponType && (
                    <Typography variant='body2' color={theme.palette.greyTransparency[100]}>
                      {winItem?.weaponType}
                      {winItem?.exterior && ` | ${shortifyExterior(winItem?.exterior)}`}
                    </Typography>
                  )}
                  <Typography variant='body2'>{winItem?.itemName}</Typography>
                </Box>

                <GiveawayItemPrice price={winItem?.price} />
              </Box>

              {/* TIMER */}
              <Box
                width={{ phxs: 1, tbxs: 1 / 2 }}
                display='flex'
                gap={{ phxs: 0, tbxs: 0.5 }}
                flexDirection='column'
                justifyContent='center'
              >
                <Typography variant='body2' color={theme.palette.greyTransparency[100]}>
                  Giveaway will end in
                </Typography>
                <DialogCountdown createdAt={createdAt} period={period} />
              </Box>
            </Box>
          </Box>

          {/* CLOSE BUTTON */}
          <IconButton
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              path: { fill: theme.palette.greyTransparency[100] }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>
  );
};

export default DialogHeader;
