import { Box, useTheme } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import { useGetGiveInfoByIdQuery } from 'store/rtk/giveaways/service';
import DialogHeader from '../Dialog/DialogHeader';
import DialogContent from '../Dialog/DialogContent';
import DialogWinnersList from '../Dialog/DialogWinnersList';

const GiveawayDialog = ({
  giveawayId,
  modalState = false,
  handleCloseModal,
  period,
  winItem,
  createdAt
}) => {
  const theme = useTheme();
  const {
    data: givInfo,
    isLoading,
    isSuccess
  } = useGetGiveInfoByIdQuery(giveawayId, { skip: !giveawayId });

  if (!giveawayId) return null;

  return (
    <Dialog
      key={'giveaway-dialog'}
      open={modalState}
      onClose={handleCloseModal}
      PaperProps={{
        sx: {
          maxHeight: '90vh',
          borderRadius: 4,
          overflow: 'hidden',
          background: theme.palette.grey[800],
          color: theme.palette.white,
          width: 800,
          m: {
            phxs: 1,
            tbxs: 4
          }
          // minHeight: 800
        }
      }}
    >
      <DialogHeader
        period={period}
        winItem={winItem}
        createdAt={createdAt}
        handleCloseModal={handleCloseModal}
      />
      <Box display='flex' flexDirection='column' gap={2} p={3} overflow='auto'>
        {isSuccess && (
          <DialogContent
            participantsCount={givInfo?.count}
            giveaway={givInfo?.giveaway}
            disabledButton={isLoading}
          />
        )}
        <DialogWinnersList list={givInfo?.previousGiveaways} />
      </Box>
    </Dialog>
  );
};

export default GiveawayDialog;
