import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

import type { ItemDetails } from 'models/types/items';
import type { User } from 'models/types/user';

export const accountApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getInventory: builder.query<AccountInventoryItem[], InventoryProps>({
      query: ({ skip, take, isActive }) => ({
        url: `/inventory-auth/me/history`,
        params: {
          skip,
          take,
          isActive
        },
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['accountInventory']
    }),
    getInventoryAmount: builder.query<number, void>({
      query: () => ({
        url: `/inventory-auth/amount`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['inventoryAmount'],
      transformResponse: (response: any) => response.amount
    }),
    getAccountCases: builder.query<any, { userId: number; skip: number; take: number }>({
      query: ({ userId, skip, take }) => ({
        url: `/cases-service/history/user/${userId}`,
        params: {
          skip,
          take
        },
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['accountCases']
    }),
    getUpgrade: builder.query<UpgradeItems[], { userId: number; skip: number; take: number }>({
      query: ({ userId, skip, take }) => ({
        url: `/upgrader-service/public/${userId}`,
        params: {
          skip,
          take
        },
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['accountUpgrade']
    }),
    getGifts: builder.query<any, { skip: number; take: number }>({
      query: ({ skip, take }) => ({
        url: `/promo-auth/user/free-cases/history`,
        params: {
          skip,
          take
        },
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['accountGifts']
    }),
    sellUserItem: builder.mutation<any, { itemId: number }>({
      query: ({ itemId }) => ({
        url: `/inventory-auth/sell`,
        method: 'post',
        body: { itemID: itemId }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['balance', 'accountInventory']
    }),
    sellAllUserItems: builder.mutation<any, void>({
      query: () => ({
        url: `/inventory-auth/sell/all`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['balance', 'accountInventory', 'inventoryAmount']
    }),
    withdrawUserItem: builder.mutation<any, number>({
      query: (userItemId) => ({
        url: `/withdraw-auth/`,
        method: 'post',
        body: { userItemId }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['accountInventory', 'inventoryAmount', 'upgraderInventory']
    }),
    getKycToken: builder.mutation<any, any>({
      query: () => ({
        url: `/withdraw-auth/kyc/begin`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    checkUserKyc: builder.query<any, any>({
      query: () => ({
        url: `/withdraw-auth/kyc/check`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    checkKycTicket: builder.mutation<void, FormData>({
      query: (body) => ({
        url: `/ladesk-auth/kyc/ticket`,
        method: 'post',
        // formData: true,
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    }),
    setTradeUrl: builder.mutation<any, string>({
      query: (tradeUrl) => ({
        url: `/user-auth/set/trade-url`,
        body: {
          tradeUrl
        },
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['user']
    }),
    confirmUserEmail: builder.mutation<any, string>({
      query: (email) => ({
        url: `/user-auth/set/send-confirmation`,
        body: {
          email
        },
        method: 'put'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['user']
    }),
    checkUserNickname: builder.query<any, void>({
      query: () => ({
        url: `/account/check`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      }
    })
  })
});

export const {
  useGetInventoryQuery,
  useGetInventoryAmountQuery,
  useGetAccountCasesQuery,
  useGetUpgradeQuery,
  useGetGiftsQuery,
  useSellUserItemMutation,
  useSellAllUserItemsMutation,
  useWithdrawUserItemMutation,
  useSetTradeUrlMutation,
  useConfirmUserEmailMutation,
  useLazyCheckUserNicknameQuery,
  // KYC
  useGetKycTokenMutation,
  useCheckUserKycQuery,
  useCheckKycTicketMutation
} = accountApi;

type AccountInventoryItem = {
  id: number;
  createdAt: string;
  updatedAt: string;
  inventoryItem: {
    item: {
      id: number;
      createdAt: string;
      updatedAt: string;
      price: number;
      steamMarketPrice: number;
      amount: number | null;
      steamMarketHashName: string;
      exterior: string;
      type: string;
      collection: string;
      volume: number;
      icon: string;
      rarityName: string;
      qualityName: string;
      weaponType: string;
      itemName: string;
    };
    price: number;
    source: string;
    id: number;
    createdAt: string;
    updatedAt: string;
    disabled: boolean;
  };
  identifier: number;
  status: string;
  extra: {
    upgradeId: number;
  };
};

type UpgradeItems = {
  id: number;
  toUpgradeItems: [
    {
      createdAt: string;
      updatedAt: string;
      source: any | null;
      price: number;
      disabled: boolean;
      item: ItemDetails;
    }
  ];
  upgradeItems: [
    {
      item: ItemDetails;
      userInventory: {
        id: number;
        createdAt: string;
        updatedAt: string;
      };
      price: number;
      source: string;
      id: number;
      createdAt: string;
      updatedAt: string;
      disabled: boolean;
    }
  ];
  side: string;
  type: string;
  user: User;
};

type InventoryProps = {
  skip: number;
  take: number;
  isActive: boolean;
};
