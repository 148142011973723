import Box from '@mui/material/Box';

import BattlesContainer from './components/Battles/BattlesContainer';
import CasesContainer from './components/CasesContainer';
import GiveawaysList from 'components/Giveaway/GiveawaysList';
import SiteContainer from 'components/SiteContainer';
import PageHead from 'components/PageHead';
import { TYPE_NAMES } from 'constants/cases-content';
import RarityCases from './components/RarityCases';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { usePromocodeModalContext } from 'context/modals/PromocodeModalProvider/PromocodeModalProvider';

const EventContainer = dynamic(() => import('./components/Event/EventContainer'), { ssr: false });

const Cases = ({ data }) => {
  const { query } = useRouter();
  const { handleModal } = usePromocodeModalContext();

  useEffect(() => {
    const openPromo = query?.promocode === 'open';
    if (openPromo) handleModal(true);
  }, [query]);

  return (
    <Box width={1}>
      <PageHead title={'DROP.SKIN - Open CS:GO Cases and Upgrade your Skins'} />
      <Box
        display='flex'
        flexDirection='column'
        gap={{
          phxs: 3,
          tbxs: 6
        }}
        width={1}
      >
        <EventContainer />
        <SiteContainer>
          <Box
            display='flex'
            flexDirection='column'
            gap={{
              phxs: 3,
              tbxs: 6
            }}
            width={1}
            zIndex={0}
          >
            <BattlesContainer />
            <CasesContainer
              title={TYPE_NAMES.special}
              list={data?.special}
              sxGrid={{
                '.case-item-image-caseImg': {
                  aspectRatio: '1 / 1'
                }
              }}
            />
            <GiveawaysList />
            <CasesContainer
              title={TYPE_NAMES.bestseller}
              list={data?.bestseller}
              sxGrid={{
                '.case-item-image-caseImg': {
                  aspectRatio: '1 / 1'
                }
              }}
            />
            <CasesContainer
              title={TYPE_NAMES.cyber}
              list={data?.cyber}
              sxGrid={{
                '.case-item-image-caseImg': {
                  aspectRatio: '1 / 1'
                }
              }}
            />
            <RarityCases title={TYPE_NAMES.rarity} list={data?.rarity} />
            <CasesContainer
              title={TYPE_NAMES.knives}
              list={data?.knives}
              sxGrid={{
                '.case-item-image-caseImg': {
                  aspectRatio: '1 / 1'
                }
              }}
            />
            <CasesContainer
              title={TYPE_NAMES.only}
              list={data?.only}
              sxGrid={{
                '.case-item-image-caseImg': {
                  aspectRatio: '1 / 1'
                }
              }}
            />
            <CasesContainer
              title={TYPE_NAMES.classic}
              list={data?.classic}
              sxGrid={{
                '.case-item-image-caseImg': {
                  aspectRatio: '1 / 1'
                }
              }}
            />
          </Box>
        </SiteContainer>
      </Box>
    </Box>
  );
};

export default Cases;
