import { memo } from 'react';
import { Avatar, Box, Typography, useTheme } from '@mui/material';

import Link from 'next/link';
import moment from 'moment';

import { shortifyExterior } from 'utils/rarity';
import WinItemImage from '../WinItemImage';

const Winner = ({ user, item }) => {
  const theme = useTheme();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      height={{
        phxs: 88,
        tbxs: 69
      }}
      borderRadius={2}
      bgcolor={theme.palette.grey[700]}
      p={{
        phxs: theme.spacing(1.5, 1),
        phlg: theme.spacing(1.5, 2),
        tbxs: theme.spacing(1, 2)
      }}
    >
      {/* LEFT */}
      <Box display='flex' height={1} gap={2} alignItems='center' position='relative'>
        <Box height={1} width={{ phxs: 50, phlg: 80, tbxs: 80 }}>
          <WinItemImage itemName={item?.itemName} itemIcon={item?.icon} />
        </Box>
        <Box display='flex' justifyContent='center' flexDirection='column'>
          <Box display='flex' gap={0.5}>
            <Typography variant='body1' color={theme.palette.grey[400]}>
              {item?.weaponType}
            </Typography>
            <Typography variant='body1' display={{ phxs: 'none', tbxs: 'block' }}>
              {item?.itemName}
            </Typography>

            {item?.exterior && (
              <>
                <Typography
                  variant='body1'
                  display={{ phxs: 'block', tbxs: 'none' }}
                  color={theme.palette.grey[400]}
                >
                  {' | '}
                </Typography>

                <Typography
                  variant='body1'
                  color={{ phxs: theme.palette.grey[400], tbxs: theme.palette.grey[500] }}
                >
                  {shortifyExterior(item?.exterior)}
                </Typography>
              </>
            )}
          </Box>

          <Typography variant='body1' display={{ phxs: 'block', tbxs: 'none' }}>
            {item?.itemName}
          </Typography>

          <Typography
            variant='body1'
            display={{ phxs: 'block', tbxs: 'none' }}
            color={theme.palette.secondaries[400]}
            fontWeight={500}
          >
            ${item?.price?.toFixed(2)}
          </Typography>

          <Typography variant='body2' color={theme.palette.greyTransparency[100]}>
            {moment(user?.date).format('DD.MM.YYYY HH:mm:ss').toString()}
          </Typography>
        </Box>
      </Box>

      {/* RIGHT */}
      <Box display='flex' height={1} gap={2} alignItems='center' position='relative'>
        <Typography
          variant='body1'
          display={{ phxs: 'none', tbxs: 'block' }}
          color={theme.palette.secondaries[400]}
          fontWeight={500}
          width={70}
        >
          ${item?.price?.toFixed(2)}
        </Typography>

        <WinnerDetails userId={user?.id} avatar={user?.avatarFull} userName={user?.name} />
      </Box>
    </Box>
  );
};

export default memo(Winner);

//------------------------

const WinnerDetails = ({ userId, avatar, userName }) => {
  return (
    <Box
      display='flex'
      gap={1}
      alignItems='center'
      width={{
        phxs: 'auto',
        tb: 200
      }}
      sx={{ cursor: 'pointer' }}
    >
      <Link href={`/profile/user/[id]`} as={`/profile/user/${userId}`} passHref>
        <Box
          display='flex'
          gap={1}
          alignItems='center'
          width={{
            phxs: 'auto',
            tb: 200
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Avatar
            alt={userName}
            src={avatar}
            variant='rounded'
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              cursor: 'pointer'
            }}
          />
          <Typography
            display={{
              phxs: 'none',
              tb: 'block'
            }}
            variant='body1'
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
          >
            {userName}
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};
