import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { useConfirmUserEmailMutation } from 'store/rtk/account/service';
import CloseIcon from 'oldAssets/new/MarketClose.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { notify } from 'store/slices/notifier';
import { useAppDispatch } from 'store';

type Inputs = {
  email: string;
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required('Please enter your email.').email('Email is invalid')
  })
  .required();

const EmailDialog = ({ openModal, setOpenModal }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({
    shouldUseNativeValidation: true,
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data) => {
    const email = data.email.trim();
    sendEmail(email)
      .unwrap()
      .then(() => {
        setOpenModal(false);
        dispatch(
          notify({
            message: 'Email has been sent successfully, check your inbox!',
            type: 'success'
          })
        );
      });
  };

  const [sendEmail, { isLoading }] = useConfirmUserEmailMutation();

  const handleCloseEmailModal = () => {
    setOpenModal(false);
  };

  return (
    <Dialog
      key={'email-confirmation-dialog'}
      open={openModal}
      onClose={handleCloseEmailModal}
      PaperProps={{
        sx: {
          maxWidth: 450,
          width: 350,
          borderRadius: 2,
          p: 2,
          m: 0,
          background: theme.palette.grey[800]
        }
      }}
    >
      <DialogTitle sx={{ px: 1 }}>
        <Box display='flex' justifyContent='center' alignItems='center'>
          <Typography variant='subtitle2' textAlign='center'>
            Confirm your email firstly!
          </Typography>

          {/* CLOSE BUTTON */}
          <IconButton
            onClick={handleCloseEmailModal}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              m: 0.5,
              path: { fill: theme.palette.greyTransparency[100] }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <OutlinedInput
          required
          autoFocus
          type='email'
          name='email'
          sx={{
            height: 48,
            width: '100%',
            background: theme.palette.grey[800],
            color: theme.palette.grey[200]
          }}
          inputProps={{
            sx: { pl: 2 },
            inputMode: 'email'
          }}
          placeholder={'Email'}
          {...register('email')}
          error={!!errors.email}
        />
      </DialogContent>
      <DialogActions sx={{ pt: 0 }}>
        <Button
          fullWidth
          type='submit'
          variant='contained'
          color='secondary'
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Send email
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailDialog;
