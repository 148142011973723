import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

export interface Giveaway {
  type: string;
  timer: string;
  [key: string]: any;
}

export const giveawaysApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getGiveaways: builder.query<Giveaway, void>({
      query: () => ({
        url: '/giveaway/globalInfo',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['giveaways']
    }),
    getGiveInfoById: builder.query<Giveaway, string>({
      query: (id) => ({
        url: `/giveaway/info/${id}?last=5`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      providesTags: ['giveawayInfo']
    }),
    giveawayParticipate: builder.mutation<Giveaway, { id; body: { captcha?: string } }>({
      query: ({ id, body }) => ({
        url: `/giveaway-auth/participate/${id}`,
        method: 'post',
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['giveaways', 'giveawayInfo']
    })
  })
});

export const { useGetGiveawaysQuery, useGetGiveInfoByIdQuery, useGiveawayParticipateMutation } =
  giveawaysApi;
