import Typography from '@mui/material/Typography';

const GiveawayItemPrice = ({ price = 0 }) => {
  return (
    <Typography variant='subtitle2' fontWeight={500}>
      ${price.toFixed(2)}
    </Typography>
  );
};

export default GiveawayItemPrice;
