import { Typography, Box, Skeleton, useTheme } from '@mui/material';

import times from 'lodash/times';
import Winner from './Winner';

const DialogWinnersList = ({ list = [] }) => {
  const theme = useTheme();

  return (
    <Box display='flex' gap={2} width={1} height={1} flexDirection='column' overflow='auto'>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Typography
          variant='h6'
          fontWeight={500}
          fontSize={{
            phxs: 20,
            phlg: 24
          }}
          color={theme.palette.primaries[400]}
        >
          Recent Winners
        </Typography>
      </Box>
      <Box display='flex' gap={1} width={1} height={1} flexDirection='column' overflow='auto'>
        <Box
          display='flex'
          gap={1}
          width={1}
          height={1}
          flexDirection='column'
          //  overflow='auto'
        >
          {list.map(({ winItem, wonUser }, i) => (
            <Winner user={wonUser} item={winItem} key={`winner-user-${i}`} />
          ))}

          {times(5 - list.length, (i) => (
            <Box
              key={`winner-list-${i}`}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              height={69}
              borderRadius={2}
              bgcolor={theme.palette.grey[700]}
              p={theme.spacing(1, 2)}
            >
              <Skeleton variant='rectangular' animation={false} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DialogWinnersList;
