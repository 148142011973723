import MilSpecWeapon from 'assets/rarity-cases/mil-spec-w.png';
import MilSpecCase from 'assets/rarity-cases/mil-spec-c.png';

import RestrictedWeapon from 'assets/rarity-cases/restricted-w.png';
import RestrictedCase from 'assets/rarity-cases/restricted-c.png';

import ClassifiedWeapon from 'assets/rarity-cases/classified-w.png';
import ClassifiedCase from 'assets/rarity-cases/classified-c.png';

import CovertWeapon from 'assets/rarity-cases/covert-w.png';
import CovertCase from 'assets/rarity-cases/covert-c.png';

import MoneyWeapon from 'assets/rarity-cases/money-w.png';
import MoneyCase from 'assets/rarity-cases/money-c.png';

import type { RarityColorKeys } from 'models/types/rarity';

type ContentItemProps = {
  caseImage: any;
  rifImage: any;
  rarity: RarityColorKeys;
  text: string;
  link: string;
  background: string;
};

export const TYPE_NAMES = {
  bestseller: 'Bestsellers',
  cyber: 'Cyber Cases',
  rarity: 'By Rarity',
  knives: 'Knives',
  only: 'Only on DROP.SKIN',
  classic: 'Classic',
  special: 'Special Cases',
};

export const CASES_CONTENT: ContentItemProps[] = [
  {
    caseImage: MilSpecCase,
    rifImage: MilSpecWeapon,
    rarity: 'mil-spec_grade',
    text: 'Mil-Spec',
    link: '/mil-spec',
    background:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(81, 104, 246, 0.3) 0%, rgba(194, 61, 222, 0) 100%), linear-gradient(180deg, rgba(81, 104, 246, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)'
  },
  {
    caseImage: RestrictedCase,
    rifImage: RestrictedWeapon,
    rarity: 'restricted',
    text: 'Restricted',
    link: '/restricted',
    background:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(127, 74, 246, 0.3) 0%, rgba(194, 61, 222, 0) 100%), linear-gradient(180deg, rgba(127, 74, 246, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)'
  },
  {
    caseImage: ClassifiedCase,
    rifImage: ClassifiedWeapon,
    rarity: 'classified',
    text: 'Classified',
    link: '/classified',
    background:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(194, 61, 222, 0.3) 0%, rgba(194, 61, 222, 0) 100%), linear-gradient(180deg, rgba(194, 61, 222, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)'
  },
  {
    caseImage: CovertCase,
    rifImage: CovertWeapon,
    rarity: 'covert',
    text: 'Covert',
    link: '/covert',
    background:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(217, 87, 81, 0.3) 0%, rgba(217, 87, 81, 0) 100%), linear-gradient(180deg, rgba(217, 87, 81, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)'
  },
  {
    caseImage: MoneyCase,
    rifImage: MoneyWeapon,
    rarity: 'active2',
    text: 'Money',
    link: '',
    background:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(57, 198, 166, 0.3) 0%, rgba(57, 198, 166, 0) 100%), linear-gradient(180deg, rgba(57, 198, 166, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)'
  }
];
