import BattleItem from './BattleItem';
import Box from '@mui/material/Box';
import { useGetActiveBattlesQuery } from '../../../../store/rtk/battle/service';
import BattleSkileton from './BattleSkileton';
import { memo, useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { io } from 'socket.io-client';
import { SOCKET_API_URL } from '../../../../@config';
import { AnimatePresence, motion } from 'framer-motion';

const BATTLE_CREATED = 'battle_created';
const BATTLE_USER_JOIN = 'battle_user_join';
const BATTLE_USER_LEFT = 'battle_user_left';
const BATTLE_STARTED = 'battle_started';
const JOIN_BATTLE_ROOM = 'join_battle_room';
const LEAVE_BATTLE_ROOM = 'leave_battle_room';
const BATTLE_FINISHED = 'battle_finish';

const sortBattles = (data) => {
  const result = cloneDeep(data).sort((a, b) => {
    if (new Date(a.createdAt) > new Date(b.createdAt)) return -1;
    if (new Date(a.createdAt) < new Date(b.createdAt)) return 1;

    return 0;
  });

  result.sort((a, b) => {
    if (a.status === 'BATTLE_CREATED' && b.status !== 'BATTLE_CREATED') return -1;
    if (b.status === 'BATTLE_CREATED' && a.status !== 'BATTLE_CREATED') return 1;

    return 0;
  });

  return result;
};

const variants = {
  initial: (i: number) => ({
    opacity: 0,
    y: -100,
    zIndex: -1,
    transition: {
      delay: 1
    }
  }),
  animate: (i: number) => ({
    opacity: 1,
    y: 0,
    zIndex: 1,
    transition: {
      delay: i * 0.4,
      duration: 2
    }
  }),
  exit: (i: number) => ({
    opacity: 0,
    y: 100,
    zIndex: -1,
    transition: {
      duration: 2
    }
  })
};

const BattlesList = ({ maxNum = 5 }) => {
  const { data, isLoading } = useGetActiveBattlesQuery({ status: 'BATTLE_CREATED' });

  const [battlesRoom, setBattlesRoom] = useState([]);
  // const [skeletonNumber, setSkeletonNumber] = useState(0);
  const numberOfItem = data?.info.length > maxNum ? maxNum : data?.info.length;

  useEffect(() => {
    // const numberOfSkeleton = isLoading ? maxNum : +maxNum - +numberOfItem;

    // setSkeletonNumber(numberOfSkeleton);

    if (data) {
      const res = sortBattles(data?.info);
      setBattlesRoom(res?.slice(0, numberOfItem));
    }
  }, [data, isLoading, maxNum]);

  useEffect(() => {
    const battleSocket = io(`${SOCKET_API_URL}/battle`, {
      transports: ['websocket', 'pooling']
    });

    const addUser = (resu) => {
      if (battlesRoom) {
        const indexOfRoom = battlesRoom.findIndex((b) => b?.socketRoomId === resu?.data.battleId);
        if (indexOfRoom !== -1) {
          const indexOfUser = battlesRoom[indexOfRoom].users.findIndex(
            (u) => u.id === resu?.data.id
          );
          if (indexOfUser === -1) {
            const newData = [...battlesRoom];
            newData[indexOfRoom].users.push(resu?.data);
            if (resu?.data.isLast) {
              newData[indexOfRoom].status = 'BATTLE_FINISHED';
            }
            setBattlesRoom(newData);
          }
        }
      }
    };

    const removeUser = (res) => {
      if (battlesRoom) {
        const indexOfRoom = battlesRoom.findIndex((b) => b?.socketRoomId === res?.data.battleId);
        if (indexOfRoom !== -1) {
          const indexOfUser = battlesRoom[indexOfRoom].users.findIndex(
            (u) => u.id === res?.data?.id
          );
          if (indexOfUser !== -1) {
            const newData = [...battlesRoom];
            newData[indexOfRoom].users.splice(indexOfUser, 1);
            setBattlesRoom(newData);
          }
        }
      }
    };

    const addNewRoom = (res) => {
      if (battlesRoom) {
        const newBattles = [{ ...res.data, status: res.key }, ...battlesRoom];
        if (newBattles.length > numberOfItem) {
          newBattles.splice(newBattles.length - 1, 1);
        }
        setBattlesRoom(newBattles);
      }
    };

    const removeBattlesRoom = (roomId) => {
      const indexOfRoom = battlesRoom.findIndex((b) => b?.socketRoomId === roomId);

      if (indexOfRoom !== -1) {
        const newBattles = [...battlesRoom];

        newBattles.splice(indexOfRoom, 1);
        // setSkeletonNumber(+maxNum - +newBattles.length);
        setBattlesRoom(newBattles);
      }
    };

    const resSortBattles = (res) => {
      const indexOfRoom = battlesRoom.findIndex((b) => b?.socketRoomId === res?.battleSockerRoom);

      if (indexOfRoom !== -1) {
        const newBattles = [...battlesRoom];

        newBattles[indexOfRoom].status = res?.key;

        const fin = sortBattles(newBattles);

        setBattlesRoom(fin);
      }
    };

    battleSocket.on(BATTLE_STARTED, () => {});

    battleSocket.on(JOIN_BATTLE_ROOM, () => {});

    battleSocket.on(LEAVE_BATTLE_ROOM, () => {});

    battleSocket.on(BATTLE_FINISHED, (res) => {
      if (res.key === 'BATTLE_CANCELED') {
        removeBattlesRoom(res.battleSockerRoom);
      } else {
        resSortBattles(res);
      }
    });

    battleSocket.on(BATTLE_CREATED, (res) => {
      addNewRoom(res);
    });

    battleSocket.on(BATTLE_USER_LEFT, (res) => {
      removeUser(res);
    });

    battleSocket.on(BATTLE_USER_JOIN, (res) => {
      addUser(res);
    });

    return () => {
      battleSocket.close();
    };
  }, [battlesRoom]);

  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isScrolledFromStart, setIsScrolledFromStart] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
        const deadZone = 10; // Buffer to control fade timing

        setIsScrolledToEnd(scrollLeft + clientWidth >= scrollWidth - deadZone);
        setIsScrolledFromStart(scrollLeft > deadZone);
      }
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data]);

  return (
    <Box display={'flex'} position={'relative'} width={1}>
      <Box
        ref={listRef}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-start',
          gap: '10px',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <AnimatePresence>
          {battlesRoom
            ?.sort((a, b) => {
              if (a.status === 'BATTLE_CREATED' && b.status !== 'BATTLE_CREATED') return -1;
              return 1;
            })
            ?.map((item, i) => (
              <Box
                key={`battle-cases-${item?.socketRoomId}`}
                component={motion.div}
                layout
                variants={variants}
                animate='animate'
                custom={i}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  // width: `calc((100% - (${maxNum - 1} * 10px)) / ${maxNum})`,
                  width: '100%',
                  height: 228,
                  scrollSnapAlign: 'center'
                }}
                minWidth={300}
              >
                <BattleItem battleItem={item} />
              </Box>
            ))}
        </AnimatePresence>

        <BattleSkileton numberOfSkeleton={maxNum - battlesRoom.length} maxNum={maxNum} />
      </Box>

      {/* Left shadow */}
      <Box
        sx={{
          display: { phxs: 'block', dt: 'none' },
          zIndex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '40px',
          height: '100%',
          background: 'linear-gradient(90deg, #17171C 0%, rgba(27, 27, 29, 0.00) 100%)',
          pointerEvents: 'none',
          opacity: isScrolledFromStart ? 1 : 0,
          transition: 'opacity 200ms ease-in-out'
        }}
      />

      {/* Right shadow */}
      <Box
        sx={{
          display: { phxs: 'block', dt: 'none' },
          zIndex: 1,
          position: 'absolute',
          top: 0,
          right: 0,
          width: '40px',
          height: '100%',
          background: 'linear-gradient(270deg, #17171C 0%, rgba(27, 27, 29, 0.00) 100%)',
          pointerEvents: 'none',
          opacity: isScrolledToEnd ? 0 : 1,
          transition: 'opacity 200ms ease-in-out'
        }}
      />
    </Box>
  );
};

export default memo(BattlesList);
