import { memo } from 'react';
import { Typography, Box, useTheme } from '@mui/material';

import { shortifyExterior } from 'utils/rarity';
import GiveawayCountdown from './GiveawayCountdown';
import ParticipantsCount from '../ParticipantsCount';
import GiveawayItemPrice from '../GiveawayItemPrice';

const GiveawayDetails = ({ period = '', createdAt, text = '', winItem, participantsCount = 0 }) => {
  const theme = useTheme();

  return (
    <Box
      width={1}
      height={1}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      alignItems='flex-start'
    >
      {/* ITEM DETAILS */}
      <Box
        gap={1}
        p={{
          phxs: theme.spacing(1, 1.5),
          tbxs: 2
        }}
        width={1}
        height={1}
        display='flex'
        flexDirection='column'
      >
        <Typography
          sx={{
            typography: {
              phxs: 'subtitle2',
              tbxs: 'subtitle1'
            }
          }}
        >
          {text}
        </Typography>
        <GiveawayCountdown createdAt={createdAt} period={period} />

        <Box height={1} display='flex' justifyContent='center' flexDirection='column'>
          {winItem?.weaponType && (
            <Typography variant='body1' color={theme.palette.greyTransparency[100]}>
              {winItem?.weaponType}
              {winItem?.exterior && ` | ${shortifyExterior(winItem?.exterior)}`}
            </Typography>
          )}
          <Typography variant='body1'>{winItem?.itemName}</Typography>
        </Box>
      </Box>

      {/* PRICE & COUNT */}
      <Box display='flex'>
        <Box
          p={theme.spacing(1, 2)}
          borderRadius={theme.spacing(0, 2, 0, 0)}
          bgcolor={theme.palette.giveaway.border[period]}
        >
          <GiveawayItemPrice price={winItem?.price} />
        </Box>
        <ParticipantsCount count={participantsCount} />
      </Box>
    </Box>
  );
};

export default memo(GiveawayDetails);
