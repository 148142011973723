import React from 'react';
import Typography from '@mui/material/Typography';

import { Case } from 'models/types/cases';
import CaseItem from './CaseItem';
import Grid from 'components/Grid';
import { Box, SxProps } from '@mui/material';
import Badge from '@mui/material/Badge';
import isEmpty from 'lodash/isEmpty';

type CasesProps = {
  title: string;
  list: Case[];
  isNew?: boolean;
  sxGrid?: SxProps;
};

const CasesContainer = ({ title = '', list = [], isNew = false, sxGrid }: CasesProps) => {
  if (isEmpty(list)) return null;

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={1} width={1}>
      {isNew ? (
        <Badge
          badgeContent={'New'}
          sx={{
            '.MuiBadge-badge': {
              right: -18,
              color: (theme) => theme.palette.drop.red,
              fontSize: 14
            }
          }}
        >
          <Typography variant='h5' textTransform='uppercase'>
            {title}
          </Typography>
        </Badge>
      ) : (
        <Typography variant='h5' textTransform='uppercase'>
          {title}
        </Typography>
      )}

      <Grid container spacing={2} justifyContent='center' sx={{ ...sxGrid }}>
        {list.map((item, index) => (
          <Grid
            item
            phxs={6}
            tb={4}
            tblg={4}
            lp={3}
            dtxs={2}
            key={item.name + '-cases-cont-' + index}
          >
            <CaseItem item={item} tokensWinRate={item?.tokensWinRate} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(CasesContainer);
