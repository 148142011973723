import Box from '@mui/material/Box';
import BattleCases from './BattleCases';
import BattleUsers from './BattleUsers';
import BattleRound from './BattleRound';
import BattleInfo from './BattleInfo';
import { useTheme } from '@mui/material';

const BattleItem = ({ battleItem }) => {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        borderRadius: 4,
        background: theme.palette.grey[800]
      }}
    >
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 1
        }}
      >
        <BattleRound rounds={battleItem?.rounds} />

        <BattleUsers
          users={battleItem?.users?.slice(0, 4)}
          players={battleItem?.players}
          size={'32px'}
          gameId={battleItem?.socketRoomId}
        />
      </Box>

      <BattleCases cases={battleItem?.cases} />

      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 1
        }}
      >
        <BattleInfo
          price={battleItem?.price}
          gameId={battleItem?.socketRoomId}
          status={battleItem?.status}
          isFull={battleItem?.users.length === battleItem?.players}
        />
      </Box>
    </Box>
  );
};

export default BattleItem;
