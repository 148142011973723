import { memo } from 'react';
import { Box } from '@mui/material';
import Image from 'next/image';
import { getSteamIconUrl } from 'utils/outerImages';

const WinItemImage = ({ itemName = '', itemIcon = '' }) => {
  return (
    <Box
      width={1}
      height={1}
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
    >
      <Image
        // unoptimized
        loading='eager'
        key={`next-image-${itemName}`}
        src={getSteamIconUrl(itemIcon)}
        alt={itemName}
        // layout='fill'
        // objectFit='contain'

        fill
        sizes='99vw'
        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
      />
    </Box>
  );
};

export default memo(WinItemImage);
