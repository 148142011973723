/* eslint-disable react/require-default-props */
import MuiGrid, { GridProps, GridSize } from '@mui/material/Grid';

interface ICustomMaterialGridProps extends GridProps {
  phxs?: boolean | GridSize;
  ph?: boolean | GridSize;
  phlg?: boolean | GridSize;
  tbxs?: boolean | GridSize;
  tb?: boolean | GridSize;
  tblg?: boolean | GridSize;
  lpxs?: boolean | GridSize;
  lp?: boolean | GridSize;
  lplg?: boolean | GridSize;
  dtxs?: boolean | GridSize;
  dt?: boolean | GridSize;
  dtlg?: boolean | GridSize;
}

const Grid = (props: ICustomMaterialGridProps) => <MuiGrid {...props} />;

export default Grid;
