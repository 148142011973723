import { Typography, Box } from '@mui/material';
import { useGetGiveawaysQuery } from 'store/rtk/giveaways/service';
import isEmpty from 'lodash/isEmpty';

import GiveawayItem from './components/GiveawayItem/GiveawayItem';
import { useState, useRef, useEffect } from 'react';

const GiveawaysList = () => {
  const { data = [] } = useGetGiveawaysQuery();

  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isScrolledFromStart, setIsScrolledFromStart] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
        const deadZone = 10; // Buffer to control fade timing

        setIsScrolledToEnd(scrollLeft + clientWidth >= scrollWidth - deadZone);
        setIsScrolledFromStart(scrollLeft > deadZone);
      }
    };

    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data]);

  if (isEmpty(data)) return null;

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={3} position='relative'>
      <Typography variant='h5' textTransform='uppercase'>
        Giveaway
      </Typography>

      <Box display={'flex'} position={'relative'} width={1}>
        <Box
          ref={listRef}
          display={'flex'}
          width={1}
          justifyContent={'space-between'}
          gap={1}
          sx={{
            overflowX: 'auto',
            scrollSnapType: 'x mandatory',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': { display: 'none' }
          }}
        >
          {data?.map((item, index) => (
            <Box
              key={`giveaway-${item?.period}`}
              display={'flex'}
              flex={'none'}
              width={{ phxs: 'auto', tbxs: 1 }}
              maxWidth={416}
              minWidth={{ phxs: 300, phlg: 400 }}
              sx={{ scrollSnapAlign: 'center' }}
            >
              <GiveawayItem item={item} />
            </Box>
          ))}
        </Box>

        {/* Left shadow */}
        <Box
          sx={{
            display: { phxs: 'block', dt: 'none' },
            position: 'absolute',
            top: 0,
            left: 0,
            width: '40px',
            height: '100%',
            background: 'linear-gradient(90deg, #17171C 0%, rgba(27, 27, 29, 0.00) 100%)',
            pointerEvents: 'none',
            opacity: isScrolledFromStart ? 1 : 0,
            transition: 'opacity 200ms ease-in-out'
          }}
        />

        {/* Right shadow */}
        <Box
          sx={{
            display: { phxs: 'block', dt: 'none' },
            position: 'absolute',
            top: 0,
            right: 0,
            width: '40px',
            height: '100%',
            background: 'linear-gradient(270deg, #17171C 0%, rgba(27, 27, 29, 0.00) 100%)',
            pointerEvents: 'none',
            opacity: isScrolledToEnd ? 0 : 1,
            transition: 'opacity 200ms ease-in-out'
          }}
        />
      </Box>
    </Box>
  );
};

export default GiveawaysList;
