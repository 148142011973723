import moment from 'moment';
import { useEffect, useState } from 'react';

const useCountdown = ({
  createdAt,
  period
}): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} => {
  const [durations, setDurations] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const [amount, unit] = period?.split(':') || '0:0';

    const getTime = () => {
      const now = moment();
      const finishDate = moment(createdAt).add(amount, unit);
      const diff = finishDate.diff(now);

      // const duration = moment.duration(moment(finishDate).subtract(now));
      const duration = moment.duration(diff);

      if (diff <= 0)
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        };

      return {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds()
      };
    };

    setDurations(getTime());

    const interval = setInterval(() => setDurations(getTime()), 500);
    return () => clearInterval(interval);
  }, []);

  return durations;
};

export default useCountdown;

interface IDuration {
  days: number | string;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
}
export const useCountdownEnd = ({ endDate }): IDuration => {
  const [durations, setDurations] = useState<IDuration>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const getTime = () => {
      const now = moment();
      const finishDate = moment(endDate);

      const diff = finishDate.diff(now);

      // const duration = moment.duration(moment(finishDate).subtract(now));
      const duration = moment.duration(diff);

      if (diff <= 0)
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        };

      return {
        days: duration?.days()?.toString()?.padStart(2, '0') || 0,
        hours: duration?.hours()?.toString()?.padStart(2, '0') || 0,
        minutes: duration?.minutes()?.toString()?.padStart(2, '0') || 0,
        seconds: duration?.seconds()?.toString()?.padStart(2, '0') || 0
      };
    };

    setDurations(getTime());

    const interval = setInterval(() => setDurations(getTime()), 500);
    return () => clearInterval(interval);
  }, [endDate]);

  return durations;
};
