import { IBattleGameInfo } from 'models/types/battle-info';
import withError from '../queryLifecycle/withError';
import { dropskinApi } from '../service';

export const battlesApi = dropskinApi.injectEndpoints({
  endpoints: (builder) => ({
    getActiveBattles: builder.query<any, any>({
      query: (params) => ({
        url: '/battle/active',
        method: 'get',
        params
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getTopBattles: builder.query<TopBattlesType[], number>({
      query: (take = 20) => ({
        url: '/battle/top',
        method: 'get',
        params: { take }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getUserBattles: builder.query<UserBattlesType, void>({
      query: () => ({
        url: '/battle-auth/',
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0
    }),
    getBattleInfo: builder.query<IBattleGameInfo, string>({
      query: (battleId) => ({
        url: `/battle/${battleId}`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0,
      providesTags: ['battleInfo']
    }),
    getPrivateBattleInfo: builder.query<IBattleGameInfo, string>({
      query: (battleId) => ({
        url: `/battle/private/${Buffer.from(battleId).toString('base64')}`,
        method: 'get'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      keepUnusedDataFor: 0,
      providesTags: ['battleInfoPrivate']
    }),
    joinBattle: builder.mutation<any, { battleId: string; place: number }>({
      query: ({ battleId, place }) => ({
        url: `/battle-auth/join/${battleId}`,
        method: 'post',
        params: { place }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['battleInfo', 'battleInfoPrivate', 'balance']
    }),
    leftBattle: builder.mutation<any, string>({
      query: (battleId) => ({
        url: `/battle-auth/leave/${battleId}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['battleInfo', 'battleInfoPrivate', 'balance']
    }),
    createBattle: builder.mutation<any, CreateBattleType>({
      query: ({ name, caseIds, players, isPrivate }) => ({
        url: '/battle-auth/create',
        method: 'post',
        body: {
          name,
          caseIds,
          players,
          isPrivate
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['balance']
    }),
    createSameBattle: builder.mutation<any, string>({
      query: (battleId) => ({
        url: `/battle-auth/create/${battleId}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['battleInfo', 'battleInfoPrivate', 'balance']
    }),
    cancelBattle: builder.mutation<any, string>({
      query: (battleId) => ({
        url: `/battle-auth/cancel/${battleId}`,
        method: 'post'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await withError({ dispatch, queryFulfilled });
      },
      invalidatesTags: ['battleInfo', 'battleInfoPrivate', 'balance']
    })
  })
});

export const {
  // useGetBattlesQuery,
  useGetActiveBattlesQuery,
  useGetTopBattlesQuery,
  useGetUserBattlesQuery,
  useGetBattleInfoQuery,
  useGetPrivateBattleInfoQuery,
  useJoinBattleMutation,
  useLeftBattleMutation,
  useCreateBattleMutation,
  useCreateSameBattleMutation,
  useCancelBattleMutation
} = battlesApi;

// TYPES -> move to /models/types

type IBattleFilters = {
  rounds?: number;
  players?: number;
  sorting?: 'ASC' | 'DESC';
  searchName?: string;
  minPrice: number;
  maxPrice: number;
};

//----------------------
type CreatorId = {
  name: string;
  avatarFull: string;
  id: number;
};

type BattleCase = {
  name: string;
  price: number;
  caseImg: string;
  rifleImg: string;
};

type BattleUser = {
  name: string;
  avatarFull: string;
  id: number;
};

type BattlesType = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  price: number;
  rounds: number;
  winnerId: number;
  socketRoomId: string;
  players: number;
  isPrivate: boolean;
  status: string;
  extra: {};
  creatorId: CreatorId;
  cases: BattleCase[];
  users: BattleUser[];
};

//----------------------

type CreateBattleType = {
  name: string;
  caseIds: number[];
  players: number;
  isPrivate: boolean;
};

type ActiveBattlesType = {
  info: BattlesType[];
  count: number;
};

type UserBattlesType = {
  battles: BattlesType[];
};

export interface User {
  name: string;
  avatarFull: string;
  id: number;
}

export interface Item2 {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  price: number;
  steamMarketPrice: number;
  amount?: any;
  steamMarketHashName: string;
  exterior: string;
  type: string;
  collection: string;
  volume: number;
  icon: string;
  rarityName: string;
  qualityName: string;
  weaponType: string;
  itemName: string;
}

export interface WinItem {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  price: number;
  odds: number;
  rangeStart: number;
  rangeEnd: number;
  active: boolean;
  item: Item2;
}

export interface Item {
  winItem: WinItem;
}

export interface TopBattlesType {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  price: number;
  rounds: number;
  caseIds: number[];
  winnerId?: any;
  socketRoomId: string;
  players: number;
  isPrivate: boolean;
  status: string;
  extra: any;
  creatorId: CreatorId;
  users: User[];
  total: string;
  items: Item[];
}
