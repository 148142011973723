export const CASES_COLOR = {
  'MIL-SPEC': {
    gradient:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(81, 104, 246, 0.3) 0%, rgba(194, 61, 222, 0) 100%), linear-gradient(180deg, rgba(81, 104, 246, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)',
    colorKey: 'Mil-Spec'
  },
  RESTRICTED: {
    gradient:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(127, 74, 246, 0.3) 0%, rgba(194, 61, 222, 0) 100%), linear-gradient(180deg, rgba(127, 74, 246, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)',
    colorKey: 'Restricted'
  },
  CLASSIFIED: {
    gradient:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(194, 61, 222, 0.3) 0%, rgba(194, 61, 222, 0) 100%), linear-gradient(180deg, rgba(194, 61, 222, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)',
    colorKey: 'Classified'
  },
  COVERT: {
    gradient:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(217, 87, 81, 0.3) 0%, rgba(217, 87, 81, 0) 100%), linear-gradient(180deg, rgba(217, 87, 81, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)',
    colorKey: 'Covert'
  },
  KNIVES: {
    gradient:
      'radial-gradient(66.82% 55.61% at 50% 0%, rgba(249, 217, 73, 0.3) 0%, rgba(249, 217, 73, 0) 100%), linear-gradient(180deg, rgba(249, 217, 73, 0.25) 0%, rgba(42, 42, 45, 0.25) 100%)',
    colorKey: 'Melee'
  }
};
