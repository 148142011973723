import Box from '@mui/material/Box';
import BattleCaseImage from './BattleCaseImage';
import CasesImage from 'oldAssets/icon/Cases.svg';

const BattleCases = ({ cases }) => {
  const images = cases?.slice(0, 3);
  const minCaseImag = 3;
  const restCase = [];

  if (images?.length < minCaseImag) {
    for (let i = 0; restCase?.length < minCaseImag - images?.length; i++) {
      restCase?.push(<CasesImage />);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '100%',
        // height: '102px',
        height: '84px',
        background: (theme) => theme.palette.grey[700],
        position: 'relative',
        padding: '0 30px',
        overflow: 'hidden'
      }}
    >
      {images.map((item, i) => (
        <BattleCaseImage item={item} key={`battle-case-image-${i}`} />
      ))}
      {restCase.length !== 0 &&
        restCase.map((item, i) => (
          <Box
            key={`Case-Image-${i}`}
            sx={{
              height: '60px',
              width: '75px',

              svg: {
                height: '60px'
              },

              path: {
                fill: (theme) => theme.palette.grey[800]
              }
            }}
          >
            {item}
          </Box>
        ))}
    </Box>
  );
};

export default BattleCases;
