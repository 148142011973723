import Cases from '../src/views/Cases';
import { wrapper } from 'store';
import { API_FOR_BUILD, API_URL } from '@config';

// export const getServerSideProps: GetServerSideProps = getServerPageChecker;

export const getStaticProps = wrapper.getStaticProps((store) => async (ctx) => {
  try {
    const response = await fetch(`${API_URL}/cases-service/`, {
      headers: {
        'Content-Type': 'application/json',
        'User-Agent':
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36'
      }
    });
    const data = await response.json();

    return {
      props: {
        data
      },
      revalidate: 1 * 60
    };
  } catch (e) {
    return {
      props: {
        data: {}
      }
    };
  }
});

export default Cases;
