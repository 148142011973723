import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from 'store';
import { useGiveawayParticipateMutation } from 'store/rtk/giveaways/service';
import { notify } from 'store/slices/notifier';

import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import moment from 'moment';
import EmailDialog from 'components/Account/Inventory/EmailDialog';
import { useGetUserQuery } from 'store/rtk/service';
import RecaptchaPopup from 'components/Google/RecaptchaPopup';

const JoinButton = ({
  giveawayId,
  createdAt,
  period = '0:s',
  disabled = false,
  disableRecaptcha = false
}) => {
  const [dis, setDis] = useState(false);
  const [emailModal, setEmailModal] = useState(false);

  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { data: userData } = useGetUserQuery(undefined, {
    skip: !isAuthenticated
  });

  const [join] = useGiveawayParticipateMutation();

  const [isRecaptchaOpen, setRecaptchaOpen] = useState(false);

  const joinRequest = async (token?: string) => {
    if (userData?.email) {
      await join({ id: giveawayId, body: { captcha: token || '' } })
        .unwrap()
        .then(() => {
          dispatch(notify({ message: "You've joined the giveaway!", type: 'success' }));
        });
    } else {
      setEmailModal(true);
    }
  };

  const handleJoin = async () => {
    if (!isAuthenticated) return;
    if (disableRecaptcha) {
      await joinRequest();
    } else {
      setRecaptchaOpen(true); // Open the reCAPTCHA popup to get a new token
    }
  };

  const handleRecaptchaVerify = async (token: string) => {
    setRecaptchaOpen(false);
    await joinRequest(token);
  };

  const toggleState = () => {
    setDis(true);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const [amount, unit] = period?.split(':');

      const getTime = () => {
        const now = moment.utc();
        const createDate = moment(createdAt);
        const finishDate = moment(createDate).add(amount, unit);
        const def = finishDate.diff(moment(now), 'seconds');
        const isClosed = def < 1;

        if (isClosed) {
          clearInterval(interval);
          toggleState();
        }
      };

      getTime();
      const interval = setInterval(() => getTime(), 1000);
      return () => clearInterval(interval);
    }
  }, []);

  // TODO: move EMAIL MODAL to ModalsWrapper
  return (
    <Box width={1}>
      <Button
        fullWidth
        color='primary'
        variant='contained'
        onClick={handleJoin}
        disabled={!isAuthenticated || dis || disabled}
      >
        Join
      </Button>
      {emailModal && <EmailDialog openModal={emailModal} setOpenModal={setEmailModal} />}
      {!disableRecaptcha && (
        <RecaptchaPopup
          open={isRecaptchaOpen}
          onClose={() => setRecaptchaOpen(false)}
          onVerify={handleRecaptchaVerify}
        />
      )}
    </Box>
  );
};

export default JoinButton;
