import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';

const BattleInfo = ({ price, gameId, isFull = false, status }) => {
  const router = useRouter();
  const handleClick = () => {
    router.push(`/battle/${gameId}`);
  };

  const isActive = status === 'BATTLE_CREATED' && !isFull;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItem: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <Typography
          variant='body1'
          fontWeight={500}
          color={(theme) => theme.palette.secondaries[400]}
        >
          ${Number(price)?.toFixed(2)}
        </Typography>
      </Box>

      <Button
        variant={isActive ? 'contained' : 'outlined'}
        color='primary'
        // size='small'
        onClick={handleClick}
      >
        {!isActive ? 'Watch the Battle' : 'Join to Battle'}
      </Button>
    </Box>
  );
};

export default BattleInfo;
