import SixHoursBg from 'assets/giveaways/6h.png';
import DailyBg from 'assets/giveaways/1d.png';
import WeeklyBg from 'assets/giveaways/7d.png';
import MonthlyBg from 'assets/giveaways/30d.png';
// import DailyBg from 'assets/giveaways/6h.png';
// import DailyBg from 'assets/giveaways/6h.png';
// import DailyBg from 'assets/giveaways/6h.png';
// import DailyBg from 'assets/giveaways/6h.png';
// import DailyBg from 'assets/giveaways/6h.png';
// import DailyBg from 'assets/giveaways/6h.png';

export const GIVEAWAY_TEMPLATES = {
  '5:m': {
    period: '5:m',
    text: '5-min Giveaway',
    image: SixHoursBg,
    color: '6:h',
    tab: '6:h'
  },
  '6:h': {
    period: '6:h',
    text: '6-hour Giveaway',
    image: SixHoursBg,
    color: '6:h',
    tab: '6:h'
  },
  '12:h': {
    period: '12:h',
    text: '12-hour Giveaway',
    image: DailyBg,
    color: '1:d',
    tab: '1:d'
  },
  '1:d': {
    period: '1:d',
    text: 'Daily Giveaway',
    image: DailyBg,
    color: '1:d',
    tab: '1:d'
  },
  '3:d': {
    period: '3:d',
    text: '3-Day Giveaway',
    image: WeeklyBg,
    color: '1:d',
    tab: '1:d'
  },
  '7:d': {
    period: '7:d',
    text: 'Weekly Giveaway',
    image: WeeklyBg,
    color: '7:d',
    tab: '7:d'
  },
  '30:d': {
    period: '30:d',
    text: 'Monthly Giveaway',
    image: MonthlyBg,
    color: '30:d',
    tab: '30:d'
  }
};
