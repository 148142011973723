import React from 'react';
import Typography from '@mui/material/Typography';

import { Case } from 'models/types/cases';
import RarityCaseItem from './RarityCaseItem';
import Grid from 'components/Grid';
import { Box, SxProps } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

type CasesProps = {
  title: string;
  list: Case[];
  sxGrid?: SxProps;
};

const RarityCases = ({ title = '', list = [], sxGrid }: CasesProps) => {
  if (isEmpty(list)) return null;

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
      <Typography variant='h5' textTransform='uppercase'>
        {title}
      </Typography>

      <Grid container spacing={2} justifyContent='center' sx={{ ...sxGrid }}>
        {list.map((item, index) => (
          <Grid item phxs={6} tb={4} lpxs={3} lp={12 / 5} key={item.name + '-cases-cont-' + index}>
            <RarityCaseItem item={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default React.memo(RarityCases);
