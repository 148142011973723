import { Box, BoxProps } from '@mui/material';
import PlayersIcon from 'oldAssets/icons/Players.svg';

const ParticipantsCount: React.FC<BoxProps & { count: number }> = ({ count = 0, ...props }) => {
  return (
    <Box display='flex' gap={0.5} px={2} justifyContent='center' alignItems='center' {...props}>
      <PlayersIcon />
      {count}
    </Box>
  );
};

export default ParticipantsCount;
