import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import IconAdd from 'oldAssets/drop/AddIcon.svg';
import Avatar from '@mui/material/Avatar';
import { useRouter } from 'next/router';

const BattleJoin = ({ size, handleClick }) => {
  return (
    <IconButton
      onClick={handleClick}
      sx={(theme) => ({
        width: size,
        height: size,
        borderRadius: 2,
        background: theme.palette.grey[800],
        border: `2px solid ${theme.palette.grey[600]}`,
        path: { fill: theme.palette.grey[600] },
        ':hover': {
          path: { fill: theme.palette.grey[400] },
          background: theme.palette.grey[700]
        }
      })}
    >
      <IconAdd />
    </IconButton>
  );
};

const UserIcon = ({ user, size }) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        borderRadius: 2
      }}
    >
      <Avatar
        alt='steam-avatar'
        src={user?.avatarFull}
        variant='rounded'
        sx={{
          width: size,
          height: size,
          borderRadius: 2
        }}
      />
    </Box>
  );
};

const BattleUsers = ({ users, players, size, gameId }) => {
  const router = useRouter();
  const handleClick = () => {
    router.push(`/battle/${gameId}`);
  };

  const List = [];

  const playersNumber = players > 4 ? 4 : players;

  for (let i = 0; i < playersNumber; i++) {
    if (users[i]) {
      List.push(<UserIcon user={users[i]} size={size} key={`user-icon-${i}`} />);
    } else {
      List.push(<BattleJoin size={size} handleClick={handleClick} key={`battle-join-${i}`} />);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        align: 'center',
        justifyContent: 'flex-start',
        gap: 0.5
      }}
    >
      {List}
    </Box>
  );
};

export default BattleUsers;
