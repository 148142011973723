import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const BattleRound = ({ rounds }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 1
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '32px',
          height: '32px',
          borderRadius: '50%',
          border: '1px solid #FFB872',
          background: 'rgba(255, 184, 114, 0.15)',
          boxShadow: `0px 0px 15px rgba(161, 123, 80, 0.6)`
        }}
      >
        <Typography variant='body2'>{rounds}</Typography>
      </Box>

      <Typography variant='body2' color={(theme) => theme.palette.primaries[400]}>
        Rounds
      </Typography>
    </Box>
  );
};

export default BattleRound;
