import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Image from 'next/legacy/image';
import { Case } from 'models/types/cases';
import { getCaseImageUrl } from 'utils/outerImages';
import Link from 'next/link';

import { lowerCaseName } from 'utils/string';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material';
import { CASES_COLOR } from 'constants/rarity-cases-color';

type CaseProps = {
  item: Case;
};

const RarityCaseItem = ({ item }: CaseProps) => {
  const theme = useTheme();

  const gradientColor = CASES_COLOR[item?.name]?.gradient;
  const colorKey = CASES_COLOR[item?.name]?.colorKey;

  const caseImage = item?.caseImg ? getCaseImageUrl(item?.caseImg) : '';
  const rifleImage = item?.rifleImg ? getCaseImageUrl(item?.rifleImg, true) : '';

  return (
    <Link passHref href={`/case/[name]`} as={`/case/${lowerCaseName(item?.name)}`}>
      <Box
        border={`1px solid ${theme.palette.grey[700]}`}
        borderRadius={4}
        p={theme.spacing(3, 0)}
        gap={1}
        sx={{
          cursor: 'pointer',
          background: gradientColor,
          '& .MuiButton-root': {
            color: theme.palette.rarity[colorKey]
          },
          ':hover': {
            '& .MuiButton-root': {
              backgroundColor: theme.palette.rarity[colorKey],
              color: theme.palette.white
            }
          }
        }}
        display='flex'
        flexDirection='column'
        width={1}
        height={'100%'}
        minHeight={{
          phxs: 240,
          tbxs: 300,
          tb: 360,
          tblg: 300,
          lpxs: 330,
          lp: 290,
          lplg: 330,
          dtxs: 300
        }}
        component={motion.div}
        initial='init'
        whileHover='hover'
        whileTap='click'
      >
        <Box
          display='flex'
          position='relative'
          width='auto'
          height='100%'
          justifyContent='center'
          component={motion.div}
          variants={{
            init: { scale: 1 },
            hover: { scale: 1.2 },
            click: { scale: 0.9 }
          }}
        >
          {item?.caseImg && (
            <Image
              unoptimized
              loading='eager'
              src={caseImage}
              alt={item?.name}
              layout='fill'
              objectFit='contain'
              // fill
              // style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              quality={50}
              placeholder='blur'
              blurDataURL={caseImage}
            />
          )}
          {item?.rifleImg && (
            <Box
              position='relative'
              width={1}
              height={1}
              component={motion.div}
              variants={{
                init: { y: 0, scale: 1 },
                hover: { y: -10, scale: 1.1 }
              }}
            >
              <Image
                unoptimized
                loading='eager'
                src={rifleImage}
                alt={item?.name}
                layout='fill'
                objectFit='contain'
                // fill
                // style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                quality={50}
                placeholder='blur'
                blurDataURL={rifleImage}
              />
            </Box>
          )}
        </Box>

        <Box display='flex' gap={1.5} flexDirection='column' alignItems='center'>
          <Typography variant='body1' fontWeight={500} textTransform='uppercase'>
            {item?.name}
            {/* {formatCaseName(item?.name)} */}
          </Typography>
          <Button
            variant='tertiary'
            size='small'
            sx={{
              minWidth: theme.spacing(17)
            }}
          >
            ${item?.price.toFixed(2)}
          </Button>
        </Box>
      </Box>
    </Link>
  );
};

export default React.memo(RarityCaseItem);
