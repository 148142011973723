import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, BoxProps, useTheme } from '@mui/material';

import JoinButton from '../Dialog/JoinButton';
import PlayersIcon from 'oldAssets/icons/Players.svg';

const LabelWrapper: React.FC<BoxProps & { label: string }> = ({
  label = '',
  children,
  ...props
}) => {
  return (
    <Box display='flex' gap={0.5} flexDirection='column' justifyContent='center' {...props}>
      <Typography variant='caption' whiteSpace='nowrap' color={(theme) => theme.palette.grey[400]}>
        {label}
      </Typography>
      <Box minHeight={30}>{children}</Box>
    </Box>
  );
};

const DialogContent = ({ participantsCount = 0, giveaway, disabledButton = false }) => {
  const theme = useTheme();

  return (
    <Box display='flex' gap={1.5} flexDirection='column'>
      <Box display='flex' gap={2}>
        <LabelWrapper label='Participants'>
          <Box display='flex' gap={0.5} sx={{ path: { fill: theme.palette.grey[400] } }}>
            <PlayersIcon />
            <Typography variant='body1'>{participantsCount}</Typography>
          </Box>
        </LabelWrapper>

        <LabelWrapper label='Giveaway ID'>
          <Typography variant='body1'>#{giveaway?.id}</Typography>
        </LabelWrapper>

        <LabelWrapper label='Conditions' display={{ phxs: 'none', tb: 'flex' }}>
          <Typography variant='body1' whiteSpace='nowrap' pl={0.5}>
            {giveaway?.criteriaType}
          </Typography>
        </LabelWrapper>

        <JoinButton
          giveawayId={giveaway?.id}
          createdAt={giveaway?.createdAt}
          period={giveaway?.period}
          disabled={disabledButton}
          disableRecaptcha={giveaway?.minDeposit > 0}
        />
      </Box>

      <LabelWrapper label='Conditions' display={{ phxs: 'flex', tb: 'none' }}>
        <Typography variant='body1' pl={0.5}>
          {giveaway?.criteriaType}
        </Typography>
      </LabelWrapper>

      <LabelWrapper label='Public hash'>
        <Box
          display='flex'
          justifyContent='center'
          p={theme.spacing(2, 0.5)}
          borderRadius={2}
          bgcolor={theme.palette.grey[700]}
        >
          <Typography
            variant='body1'
            color={theme.palette.grey[400]}
            sx={{
              textAlign: 'center',
              overflowWrap: 'anywhere'
            }}
          >
            {giveaway?.publicHash}
          </Typography>
        </Box>
      </LabelWrapper>
    </Box>
  );
};

export default DialogContent;
