import { SITE_URL } from '@config';
import Head from 'next/head';
import { useRouter } from 'next/router';

const defaultTitle = 'DROP.SKIN - Open CS:GO Cases and Upgrade your Skins';
const defaultDescription =
  'DROP.SKIN transform your cheap skins into worthy and expensive ones! Cool CSGO SKINS inventory has never been so affordable!';
const defaultImage = 'https://drop.skin/favicon.svg';

const PageHead = ({ title = '', description = '', shortTitle = null, imageUrl = null }) => {
  const { asPath } = useRouter();

  return (
    <Head>
      <title>{title || defaultTitle}</title>
      <meta name='description' content={description || defaultDescription} />

      <meta property='og:description' content={description || defaultDescription} />
      <meta property='og:title' content={title} />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={`${SITE_URL}${asPath}`} />
      <meta property='og:site_name' content='DROP.SKIN' />
      <meta property='og:image' content={imageUrl || defaultImage} />
      <meta property='og:locale' content='en_US' />

      {/* TWITTER TAGS */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@drop_skin' />
      <meta name='twitter:image' content={imageUrl} />
      <meta name='twitter:title' content={shortTitle} />
      <meta name='twitter:description' content={description || defaultDescription} />
      {/* ------------ */}

      <link rel='canonical' href={`${SITE_URL}${asPath}`} />
      <meta name='theme-color' content='#000000' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' key='viewport' />
      <meta name='robots' content='index, follow' />
      <meta name='googlebot' content='all' />
      <meta charSet='UTF-8' />
    </Head>
  );
};

export default PageHead;
